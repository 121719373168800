<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form :rules="rules" @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3">Reset Password</sdHeading>
        <a-form-item label="Email Address" name="email">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item label="Password" name="password">
          <a-input-password v-model:value="formState.password" placeholder="Password" />
        </a-form-item>
        <a-form-item label="Confirm New Password" name="confirmNewPassword">
          <a-input-password v-model:value="formState.confirmNewPassword" placeholder="Confirm New Password" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-reset" htmlType="submit" type="primary" size="large"> Reset Password </sdButton>
        </a-form-item>
        <p class="return-text">Go to <router-link to="/auth/login">Sign In</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive } from 'vue';
import { AuthWrapper } from './style';
import { notification } from "ant-design-vue";
import { DataService } from "@/config/dataService/dataService";
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const ResetPassword = {

  name: 'ResetPassword',
  components: { AuthWrapper },
  setup() {
    const $route = useRoute();
    const email = ref('');
    const token = ref('');
    let validatePass = async (rules, value) => {
      if (value === "") {
        return Promise.reject("Please input the password again");
      } else if (value !== formState.password) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      email: [
        {
          required: true,
          message: "Mail is required",
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: "Password is required",
          trigger: "change",
        },
      ],
      confirmNewPassword: [
        { required: true, validator: validatePass, trigger: "change" },
      ],
    };

    const formState = reactive({
      email: '',
      password: '',
      confirmNewPassword: '',
    });
    const handleSubmit = async () => {
      formState.token = token;
      try {
        var response;
        response = await DataService.post(
          "api/Auth/ResetPassword",
          formState
        );

        if (response) {
          if (response.succeeded)
            notification.success({
              message: response.message,
            });
        } else {
          notification.error({
            message: response.message,
          });
        }
      } catch (err) {
        notification.error({
          message: "Error while sending mail",
        });
      }
    };


    onMounted(() => {
      email.value = $route.query.Email;
      token.value = $route.query.Token;
    });

    return {
      handleSubmit,
      formState,
      rules
    };
  },
};

export default ResetPassword;
</script>
